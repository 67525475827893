import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import ErrorPage from 'commonComponents/errorPage/ErrorPage';
import { css } from '@emotion/core';
import BeatLoader from 'react-spinners/BeatLoader';
import Pagination from 'commonComponents/pagination/Pagination';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import EditBrandAndModel from 'superAdminViews/setting/carOptions/EditBrandAndModel';
import style from 'superAdminViews/setting/defaults/DriverReadyComments.module.scss';

const GET_CAR_TYPES = gql`
	query getReqCarTypesByAdmin($limit: Int, $skip: Int) {
		getReqCarTypesByAdmin(pagination: { limit: $limit, skip: $skip }) {
			_id
			name
			logoUrl
			tripType
			increasePricePercent
			DistanceBasePricePerKM
			PerMinute
			WaitTimePricePerMin
			BookingFee
			BaseFare
			maximumPassengersCount
			maximumWeight
			maximumVolume
			description
		}
	}
`;

const GET_CAR_TYPES_COUNT = gql`
	query getReqCarTypesByAdminCount {
		getReqCarTypesByAdminCount
	}
`;

const CarTypes = (props) => {
	const perPage = 10;
	const [pageCount, setPageCount] = useState(0);

	const [errorModal, setErrorModal] = useState(false);
	const [errorText, setErrorText] = useState('');
	const [modal, setModal] = useState(false);
	const [carTypeDetails, setCarTypeDetails] = useState();

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	const onSelectCarTypeEdit = (carType) => {
		setModal(true);
		setCarTypeDetails(carType);
	};

	const [variables, setVariables] = useState({
		limit: perPage,
		skip: perPage * pageCount,
	});

	const { loading, error, data, refetch } = useQuery(GET_CAR_TYPES, {
		variables,
		fetchPolicy: 'network-only',
		notifyOnNetworkStatusChange: true,
	});

	const { loading: countLoading, error: countError, data: countData } = useQuery(GET_CAR_TYPES_COUNT, {
		variables: variables,
		fetchPolicy: 'network-only',
	});

	// for pagination
	const onNextPage = () => {
		if (pageCount !== Math.ceil(countData.getReqCarTypesByAdminCount / perPage) - 1) {
			setPageCount((pageCount) => pageCount + 1);
		}
	};
	const onPrevPage = () => {
		if (pageCount !== 0) {
			setPageCount((pageCount) => pageCount - 1);
		}
	};
	const onFirstPage = () => {
		setPageCount(0);
	};
	const onLastPage = () => {
		setPageCount(Math.ceil(countData.getReqCarTypesByAdminCount / perPage) - 1);
	};
	useEffect(() => {
		setVariables((variables) => ({ ...variables, skip: perPage * pageCount }));
	}, [pageCount]);

	if (loading)
		return (
			<div style={{ textAlign: 'center', marginTop: '200px' }}>
				<BeatLoader
					css={css`
						display: block;
						margin: 0 auto;
						border-color: red;
					`}
					size={15}
					margin={2}
					color={'gray'}
					loading={true}
				/>
			</div>
		);
	if (error) {
		console.log(error);
		return <ErrorPage />;
	}

	return (
		<div className={style.mainDiv}>
			<ReactTable
				data={data.getReqCarTypesByAdmin.map((carType) => ({
					name: carType.name,
					tripType: carType.tripType,
					maxPassenger: carType.maximumPassengersCount,
					actions: (
						<div>
							<button
								onClick={() => onSelectCarTypeEdit(carType)}
								className={`${style.inlineButton} ${style.inlineEditButton}`}
							>
								edit
							</button>
						</div>
					),
				}))}
				columns={[
					{
						Header: <div className={style.fitTitleDiv}>Name</div>,
						accessor: 'name',
						sortable: false,
						filterable: false,
					},
					{
						Header: <div className={style.fitTitleDiv}>Trip type</div>,
						accessor: 'tripType',
						sortable: false,
						filterable: false,
					},
					{
						Header: <div className={style.fitTitleDiv}>Max passenger</div>,
						accessor: 'maxPassenger',
						sortable: false,
						filterable: false,
					},
					{
						Header: <div className={style.fitTitleDiv}>Actions</div>,
						accessor: 'actions',
						sortable: false,
						filterable: false,
						width: 60,
					},
				]}
				defaultPageSize={perPage}
				showPaginationTop={false}
				showPaginationBottom={false}
				className="-striped -highlight"
			/>
			{countData && (
				<Pagination
					pageCount={pageCount + 1}
					totalCount={Math.ceil(countData.getReqCarTypesByAdminCount / perPage)}
					onPrevPage={onPrevPage}
					onNextPage={onNextPage}
					onFirstPage={onFirstPage}
					onLastPage={onLastPage}
				/>
			)}
			<Dialog open={modal} transition={Transition} >
				<DialogContent id="modal-slide-description">
					<EditBrandAndModel
						refetch={() => refetch()}
						close={() => setModal(false)}
						type="carType"
						item={carTypeDetails}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default CarTypes;
