import React, { useState, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import Close from '@material-ui/icons/Close';
import SelectSearch from 'react-select-search';
import { SketchPicker } from 'react-color';
import style from 'superAdminViews/setting/defaults/EditItem.module.scss';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Modal from 'commonComponents/modal/Modal';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import Button from 'components/CustomButtons/Button.js';
import CustomInput from 'components/CustomInput/CustomInput.js';

const UPDATE_MODEL = gql`
	mutation updateCarModelByAdmin($id: ID!, $name: String!, $brand: ID!) {
		updateCarModelByAdmin(id: $id, input: { name: $name, brand: $brand }) {
			_id
			name
			brand {
				_id
				name
			}
		}
	}
`;

const UPDATE_BRAND = gql`
	mutation updateCarBrandByAdmin($id: ID!, $name: String!) {
		updateCarBrandByAdmin(id: $id, input: { name: $name }) {
			_id
			name
		}
	}
`;

const UPDATE_COLOR = gql`
	mutation updateCarColorByAdmin($id: ID!, $name: String!, $code: String!) {
		updateCarColorByAdmin(id: $id, input: { name: $name, code: $code }) {
			_id
			name
			code
		}
	}
`;

const UPDATE_CAR_TYPE = gql`
	mutation updateReqCarTypeByAdmin(
		$id: ID
		$increasePricePercent: Int!
		$DistanceBasePricePerKM: Float!
		$PerMinute: Float!
		$WaitTimePricePerMin: Float!
		$BookingFee: Float!
		$BaseFare: Float!
		$maximumPassengersCount: Int!
		$maximumWeight: Int!
		$maximumVolume: Int!
		$description: String!
	) {
		updateReqCarTypeByAdmin(
			filters: { _id: $id }
			input: {
				increasePricePercent: $increasePricePercent
				DistanceBasePricePerKM: $DistanceBasePricePerKM
				PerMinute: $PerMinute
				WaitTimePricePerMin: $WaitTimePricePerMin
				BookingFee: $BookingFee
				BaseFare: $BaseFare
				maximumPassengersCount: $maximumPassengersCount
				maximumWeight: $maximumWeight
				maximumVolume: $maximumVolume
				description: $description
			}
		) {
			_id
			name
			logoUrl
			tripType
			increasePricePercent
			DistanceBasePricePerKM
			PerMinute
			WaitTimePricePerMin
			BookingFee
			BaseFare
			maximumPassengersCount
			maximumWeight
			maximumVolume
			description
		}
	}
`;

const GET_BRANDS = gql`
	query getCarBrands($limit: Int, $skip: Int, $name: String) {
		getCarBrands(pagination: { limit: $limit, skip: $skip }, filters: { name: $name }) {
			_id
			name
		}
	}
`;

const EditBrandAndModel = ({ type, item, close, refetch }) => {
	const [brandSelectOptions, setBrandSelectOptions] = useState([]);

	const [
		getCarBrands,
		{ loading: brandsLazyLoading, error: brandsLazyError, data: brandsLazyData, fetchMore },
	] = useLazyQuery(GET_BRANDS);

	useEffect(() => {
		if (brandsLazyData) {
			setBrandSelectOptions(
				Array.from(brandsLazyData.getCarBrands, (brand) => ({
					name: brand.name,
					value: brand._id,
				}))
			);
		}
	}, [, brandsLazyData, brandsLazyLoading]);

	const [fetchMoreSkip, setFetchMoreSkip] = useState(10);
	const [search, setSearch] = useState(type === 'model' ? item.brand.name : '');

	useEffect(() => {
		const handler = setTimeout(() => {
			getCarBrands({
				variables: {
					limit: 10,
					skip: 0,
					name: search ? search.trim() : '',
				},
			});
		}, 200);
		return () => clearTimeout(handler);
	}, [search]);

	const onLoadMore = () => {
		fetchMore({
			query: GET_BRANDS,
			variables: {
				limit: 10,
				skip: fetchMoreSkip,
				name: search ? search : '',
			},
			updateQuery: (previousResult, { fetchMoreResult }) => {
				setBrandSelectOptions((brandSelectOptions) => [
					...brandSelectOptions,
					...Array.from(fetchMoreResult.getCarBrands, (brand) => ({
						name: brand.name,
						value: brand._id,
					})),
				]);
			},
		});
		setFetchMoreSkip((fetchMoreSkip) => fetchMoreSkip + 10);
	};

	const handleScroll = (e) => {
		let element = e.target;
		if (
			Number(element.scrollHeight).toFixed(0) - Number(element.scrollTop).toFixed(0) ==
			Number(element.clientHeight).toFixed(0)
		) {
			onLoadMore();
		}
	};

	const [errorText, setErrorText] = useState('');
	const [errorModal, setErrorModal] = useState(false);

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	const [increasePricePercent, setIncreasePricePercent] = useState(
		type === 'carType' ? item.increasePricePercent : ''
	);
	const [DistanceBasePricePerKM, setDistanceBasePricePerKM] = useState(
		type === 'carType' ? item.DistanceBasePricePerKM : ''
	);
	const [PerMinute, setPerMinute] = useState(type === 'carType' ? item.PerMinute : '');
	const [WaitTimePricePerMin, setWaitTimePricePerMin] = useState(type === 'carType' ? item.WaitTimePricePerMin : '');
	const [BookingFee, setBookingFee] = useState(type === 'carType' ? item.BookingFee : '');
	const [BaseFare, setBaseFare] = useState(type === 'carType' ? item.BaseFare : '');
	const [maximumPassengersCount, setMaximumPassengersCount] = useState(
		type === 'carType' ? item.maximumPassengersCount : ''
	);
	const [maximumWeight, setMaximumWeight] = useState(type === 'carType' ? item.maximumWeight : '');
	const [maximumVolume, setMaximumVolume] = useState(type === 'carType' ? item.maximumVolume : '');

	const [description, setDescription] = useState(type === 'carType' ? item.description : '');

	const [increasePricePercentError, setIncreasePricePercentError] = useState(false);

	const [DistanceBasePricePerKMError, setDistanceBasePricePerKMError] = useState(false);

	const [PerMinuteError, setPerMinuteError] = useState(false);

	const [WaitTimePricePerMinError, setWaitTimePricePerMinError] = useState(false);
	
	const [BookingFeeError, setBookingFeeError] = useState(false);

	const [BaseFareError, setBaseFareError] = useState(false);

	const [maximumPassengersCountError, setMaximumPassengersCountError] = useState(false);

	const [maximumWeightError, setMaximumWeightError] = useState(false);

	const [maximumVolumeError, setmaximumVolumetError] = useState(false);
	
	const [descriptionError, setDescriptionError] = useState(false);

	const [code, setCode] = useState(type === 'color' && item.code);
	const [brandError, setBrandError] = useState();

	const [name, setName] = useState(item.name);
	const [brandId, setBrandId] = useState(type === 'model' && item.brand._id);

	const [displayColorPicker, setDisplayColorPicker] = useState(false);

	const [nameError, setNameError] = useState(false);

	const [updateCarModelByAdmin, { data: updateModelData, error: updateModelError }] = useMutation(UPDATE_MODEL);

	const [updateCarBrandByAdmin, { data: updateBrandData, error: updateBrandError }] = useMutation(UPDATE_BRAND);

	const [updateCarColorByAdmin, { data: updateColorData, error: updateColorError }] = useMutation(UPDATE_COLOR);

	const [updateReqCarTypeByAdmin, { data: updateCarTypeData, error: updateCarTypeError }] = useMutation(
		UPDATE_CAR_TYPE
	);

	const onChange = () => {

		if (!name) {
			setNameError(true);
		} else {
			setNameError(false);
		}
		if (type === 'model' && !brandId) {
			setBrandError(true);
		} else {
			setBrandError(false);
		}
		if (type === 'model' && name && brandId) {
			updateCarModelByAdmin({
				variables: { id: item._id, name: name.trim(), brand: brandId },
			})
				.then((res) => {
					setName('');
					refetch();
					close();
				})
				.catch((err) => {
					console.log(err);
					if (err.graphQLErrors && err.graphQLErrors.length > 0) {
						setErrorText(err.graphQLErrors[0].message);
						setErrorModal(true);
					}
				});
		}
		if (type === 'brand' && name) {
			updateCarBrandByAdmin({ variables: { id: item._id, name: name.trim() } })
				.then((res) => {
					setName('');
					refetch();
					close();
				})
				.catch((err) => {
					console.log(err);
					if (err.graphQLErrors && err.graphQLErrors.length > 0) {
						setErrorText(err.graphQLErrors[0].message);
						setErrorModal(true);
					}
				});
		}
		if (
			type === 'carType' &&
			increasePricePercent &&
			DistanceBasePricePerKM &&
			PerMinute &&
			WaitTimePricePerMin &&
			BookingFee &&
			BaseFare &&
			maximumPassengersCount &&
			maximumWeight &&
			maximumVolume &&
			description
		) {
			console.log("result: ", JSON.stringify({
				variables: {
					id: item._id,
					increasePricePercent: parseInt(increasePricePercent),
					DistanceBasePricePerKM: parseFloat(DistanceBasePricePerKM),
					PerMinute: parseFloat(PerMinute),
					WaitTimePricePerMin: parseFloat(WaitTimePricePerMin),
					BookingFee: parseFloat(BookingFee),
					BaseFare: parseFloat(BaseFare),
					maximumPassengersCount: parseInt(maximumPassengersCount),
					maximumWeight: parseInt(maximumWeight),
					maximumVolume: parseInt(maximumVolume),
					description,
				},
			}, null, 4))
			updateReqCarTypeByAdmin({
				variables: {
					id: item._id,
					increasePricePercent: parseInt(increasePricePercent),
					DistanceBasePricePerKM: parseFloat(DistanceBasePricePerKM),
					PerMinute: parseFloat(PerMinute),
					WaitTimePricePerMin: parseFloat(WaitTimePricePerMin),
					BookingFee: parseFloat(BookingFee),
					BaseFare: parseFloat(BaseFare),
					maximumPassengersCount: parseInt(maximumPassengersCount),
					maximumWeight: parseInt(maximumWeight),
					maximumVolume: parseInt(maximumVolume),
					description,
				},
			})
				.then((res) => {
					console.log(res);
					refetch();
					close();
				})
				.catch((err) => {
					console.log(err);
					if (err.graphQLErrors && err.graphQLErrors.length > 0) {
						setErrorText(err.graphQLErrors[0].message);
						setErrorModal(true);
					}
				});
		}
		if (type === 'color' && name && code) {
			updateCarColorByAdmin({
				variables: { id: item._id, name: name.trim(), code },
			})
				.then((res) => {
					setName('');
					setCode('#FFFFFF');
					close();
				})
				.catch((err) => {
					console.log(err);
					if (err.graphQLErrors && err.graphQLErrors.length > 0) {
						setErrorText(err.graphQLErrors[0].message);
						setErrorModal(true);
					}
				});
		}
	};

	return (
		<div className={style.mainDiv}>
			<div className={style.header}>
				<div>
					<Button justIcon key="close" aria-label="Close" color="transparent" onClick={close}>
						<Close />
					</Button>
				</div>
				<div className={style.title}>
					<h4>Edit</h4>
				</div>
			</div>
			<div className={style.content}>
				<GridContainer>
					{type === 'model' && (
						<GridItem xs={12} sm={12} md={12} lg={12}>
							<div className={style.row}>
								<GridContainer>
									<GridItem xs={12} sm={5} md={5} lg={5}>
										<div className={style.label}>Brand: </div>
									</GridItem>
									<GridItem xs={12} sm={7} md={7} lg={7}>
										{brandError && (
											<div className={style.selectSearchError}>brand can't be empty*</div>
										)}
										<div className={style.dropdownContainer}>
											<div>
												<SelectSearch
													options={brandSelectOptions}
													value={brandId}
													onScroll={(e) => handleScroll(e)}
													getOptions={(searchWord) => {
														setSearch(searchWord);
													}}
													name=""
													search
													placeholder="Choose brand"
													onChange={(value) => setBrandId(value)}
												/>
											</div>
										</div>
									</GridItem>
								</GridContainer>
							</div>
						</GridItem>
					)}
					{type === 'color' && (
						<GridItem xs={12} sm={12} md={12} lg={12}>
							<div className={style.row}>
								<GridContainer>
									<GridItem xs={12} sm={5} md={5} lg={5}>
										<div className={style.colorLabel}>Code: </div>
									</GridItem>
									<GridItem xs={12} sm={7} md={7} lg={7}>
										<div>
											<div
												style={{
													padding: '5px',
													background: '#fff',
													borderRadius: '1px',
													boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
													display: 'inline-block',
													cursor: 'pointer',
												}}
												onClick={() =>
													setDisplayColorPicker((displayColorPicker) => !displayColorPicker)
												}
											>
												<div
													style={{
														width: '36px',
														height: '14px',
														borderRadius: '2px',
														background: code,
													}}
												></div>
											</div>
											{displayColorPicker ? (
												<div style={{ position: 'absolute', zIndex: '2' }}>
													<div
														style={{
															position: 'fixed',
															top: '0px',
															right: '0px',
															bottom: '0px',
															left: '0px',
														}}
														onClick={() => setDisplayColorPicker(false)}
													/>
													<SketchPicker
														color={code}
														onChange={(color) => setCode(color.hex)}
													/>
												</div>
											) : null}
										</div>
									</GridItem>
								</GridContainer>
							</div>
						</GridItem>
					)}
					{type !== 'carType' && (
						<GridItem xs={12} sm={12} md={12} lg={12}>
							<div className={style.row}>
								<GridContainer>
									<GridItem xs={12} sm={5} md={5} lg={5}>
										<div className={style.inputLabel}>Name: </div>
									</GridItem>
									<GridItem xs={12} sm={7} md={7} lg={7}>
										<div className={style.dropdownContainer}>
											<CustomInput
												value={name}
												onChange={(e) => setName(e.target.value)}
												error={nameError}
												labelText={nameError ? "name can't be empty*" : 'name'}
												id="float"
												formControlProps={{
													fullWidth: true,
												}}
											/>
										</div>
									</GridItem>
								</GridContainer>
							</div>
						</GridItem>
					)}
					{type === 'carType' && (
						<GridItem xs={12} sm={12} md={12} lg={12}>
							<div className={style.row}>
								<GridContainer>
									<GridItem xs={12} sm={5} md={5} lg={5}>
										<div className={style.inputLabel}>Increase price percent: </div>
									</GridItem>
									<GridItem xs={12} sm={7} md={7} lg={7}>
										<div className={style.dropdownContainer}>
											<CustomInput
												value={increasePricePercent}
												onChange={(e) => setIncreasePricePercent(e.target.value)}
												error={increasePricePercentError}
												labelText={
													increasePricePercentError
														? "Increase Price Percent can't be empty*"
														: 'Increase Price Percent'
												}
												id="float"
												formControlProps={{
													fullWidth: true,
												}}
											/>
										</div>
									</GridItem>
								</GridContainer>
							</div>
						</GridItem>
					)}
					{type === 'carType' && (
						<GridItem xs={12} sm={12} md={12} lg={12}>
							<div className={style.row}>
								<GridContainer>
									<GridItem xs={12} sm={5} md={5} lg={5}>
										<div className={style.inputLabel}>Distance base price per km: </div>
									</GridItem>
									<GridItem xs={12} sm={7} md={7} lg={7}>
										<div className={style.dropdownContainer}>
											<CustomInput
												value={DistanceBasePricePerKM}
												onChange={(e) => setDistanceBasePricePerKM(e.target.value)}
												error={DistanceBasePricePerKMError}
												labelText={
													DistanceBasePricePerKMError
														? "Distance Base Price Per KM can't be empty*"
														: 'Distance Base Price Per KM'
												}
												id="float"
												formControlProps={{
													fullWidth: true,
												}}
											/>
										</div>
									</GridItem>
								</GridContainer>
							</div>
						</GridItem>
					)}
					{type === 'carType' && (
						<GridItem xs={12} sm={12} md={12} lg={12}>
							<div className={style.row}>
								<GridContainer>
									<GridItem xs={12} sm={5} md={5} lg={5}>
										<div className={style.inputLabel}>Per minute: </div>
									</GridItem>
									<GridItem xs={12} sm={7} md={7} lg={7}>
										<div className={style.dropdownContainer}>
											<CustomInput
												value={PerMinute}
												onChange={(e) => setPerMinute(e.target.value)}
												error={PerMinuteError}
												labelText={PerMinuteError ? "Per Minute can't be empty*" : 'Per Minute'}
												id="float"
												formControlProps={{
													fullWidth: true,
												}}
											/>
										</div>
									</GridItem>
								</GridContainer>
							</div>
						</GridItem>
					)}
					{type === 'carType' && (
						<GridItem xs={12} sm={12} md={12} lg={12}>
							<div className={style.row}>
								<GridContainer>
									<GridItem xs={12} sm={5} md={5} lg={5}>
										<div className={style.inputLabel}>WaitTime Price Per Minute: </div>
									</GridItem>
									<GridItem xs={12} sm={7} md={7} lg={7}>
										<div className={style.dropdownContainer}>
											<CustomInput
												value={WaitTimePricePerMin}
												onChange={(e) => setWaitTimePricePerMin(e.target.value)}
												error={WaitTimePricePerMinError}
												labelText={WaitTimePricePerMinError ? "WaitTime Price Per Minute can't be empty*" : 'WaitTime Price Per Minute'}
												id="float"
												formControlProps={{
													fullWidth: true,
												}}
											/>
										</div>
									</GridItem>
								</GridContainer>
							</div>
						</GridItem>
					)}
					{type === 'carType' && (
						<GridItem xs={12} sm={12} md={12} lg={12}>
							<div className={style.row}>
								<GridContainer>
									<GridItem xs={12} sm={5} md={5} lg={5}>
										<div className={style.inputLabel}>Booking fee(+base fare-sitting in): </div>
									</GridItem>
									<GridItem xs={12} sm={7} md={7} lg={7}>
										<div className={style.dropdownContainer}>
											<CustomInput
												value={BookingFee}
												onChange={(e) => setBookingFee(e.target.value)}
												error={BookingFeeError}
												labelText={
													BookingFeeError ? "Booking Fee can't be empty*" : 'Booking Fee'
												}
												id="float"
												formControlProps={{
													fullWidth: true,
												}}
											/>
										</div>
									</GridItem>
								</GridContainer>
							</div>
						</GridItem>
					)}
					{type === 'carType' && (
						<GridItem xs={12} sm={12} md={12} lg={12}>
							<div className={style.row}>
								<GridContainer>
									<GridItem xs={12} sm={5} md={5} lg={5}>
										<div className={style.inputLabel}>Base fare(for cancellation) : </div>
									</GridItem>
									<GridItem xs={12} sm={7} md={7} lg={7}>
										<div className={style.dropdownContainer}>
											<CustomInput
												value={BaseFare}
												onChange={(e) => setBaseFare(e.target.value)}
												error={BaseFareError}
												labelText={BaseFareError ? "Base Fare can't be empty*" : 'Base Fare'}
												id="float"
												formControlProps={{
													fullWidth: true,
												}}
											/>
										</div>
									</GridItem>
								</GridContainer>
							</div>
						</GridItem>
					)}
					{type === 'carType' && (
						<GridItem xs={12} sm={12} md={12} lg={12}>
							<div className={style.row}>
								<GridContainer>
									<GridItem xs={12} sm={5} md={5} lg={5}>
										<div className={style.inputLabel}>Maximum passengers count: </div>
									</GridItem>
									<GridItem xs={12} sm={7} md={7} lg={7}>
										<div className={style.dropdownContainer}>
											<CustomInput
												value={maximumPassengersCount}
												onChange={(e) => setMaximumPassengersCount(e.target.value)}
												error={maximumPassengersCountError}
												labelText={
													maximumPassengersCountError
														? "Maximum Passengers Count can't be empty*"
														: 'Maximum Passengers Count'
												}
												id="float"
												formControlProps={{
													fullWidth: true,
												}}
											/>
										</div>
									</GridItem>
								</GridContainer>
							</div>
						</GridItem>
					)}
					{type === 'carType' && (
						<GridItem xs={12} sm={12} md={12} lg={12}>
							<div className={style.row}>
								<GridContainer>
									<GridItem xs={12} sm={5} md={5} lg={5}>
										<div className={style.inputLabel}>Maximum weight: </div>
									</GridItem>
									<GridItem xs={12} sm={7} md={7} lg={7}>
										<div className={style.dropdownContainer}>
											<CustomInput
												value={maximumWeight}
												onChange={(e) => setMaximumWeight(e.target.value)}
												error={maximumWeightError}
												labelText={
													maximumWeightError
														? "Maximum Weight can't be empty*"
														: 'Maximum Weight'
												}
												id="float"
												formControlProps={{
													fullWidth: true,
												}}
											/>
										</div>
									</GridItem>
								</GridContainer>
							</div>
						</GridItem>
					)}
					{type === 'carType' && (
						<GridItem xs={12} sm={12} md={12} lg={12}>
							<div className={style.row}>
								<GridContainer>
									<GridItem xs={12} sm={5} md={5} lg={5}>
										<div className={style.inputLabel}>Maximum volume: </div>
									</GridItem>
									<GridItem xs={12} sm={7} md={7} lg={7}>
										<div className={style.dropdownContainer}>
											<CustomInput
												value={maximumVolume}
												onChange={(e) => setMaximumVolume(e.target.value)}
												error={maximumVolumeError}
												labelText={
													maximumVolumeError
														? "Maximum Volume can't be empty*"
														: 'Maximum Volume'
												}
												id="float"
												formControlProps={{
													fullWidth: true,
												}}
											/>
										</div>
									</GridItem>
								</GridContainer>
							</div>
						</GridItem>
					)}
					{type === 'carType' && (
						<GridItem xs={12} sm={12} md={12} lg={12}>
							<div className={style.row}>
								<GridContainer>
									<GridItem xs={12} sm={5} md={5} lg={5}>
										<div className={style.inputLabel}>Description: </div>
									</GridItem>
									<GridItem xs={12} sm={7} md={7} lg={7}>
										<div className={style.dropdownContainer}>
											<CustomInput
												value={description}
												onChange={(e) => setDescription(e.target.value)}
												error={descriptionError}
												labelText={
													descriptionError ? "Description can't be empty*" : 'Description'
												}
												id="float"
												formControlProps={{
													fullWidth: true,
												}}
											/>
										</div>
									</GridItem>
								</GridContainer>
							</div>
						</GridItem>
					)}
					<GridItem xs={12} sm={12} md={12} lg={12}>
						<div className={style.buttonsContainer}>
							<Button color="whiteButton" onClick={() => close()}>
								Cancel
							</Button>
							<Button color="yellow" onClick={onChange}>
								Submit
							</Button>
						</div>
					</GridItem>
				</GridContainer>
			</div>
			<Dialog open={errorModal} transition={Transition}>
				<DialogContent id="modal-slide-description">
					<Modal
						close={() => setErrorModal(false)}
						type="notif"
						text={errorText ? errorText : 'Error! Fill The Inputs Correctly And Try Again'}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default EditBrandAndModel;
